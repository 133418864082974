import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import {ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SelectLanguageModalComponent} from 'src/app/components/select-language-modal/select-language-modal.component';
import {Api} from 'src/smoothr-web-app-core/api/api';
import {SelectVenuePage} from '../select-venue/select-venue.page';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.avec.html',
	styleUrls: ['./home.page.avec.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = ':venueId/:tableId';

	static venueUrl = ':venueId';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	activeVenue: Venue = null;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private route: ActivatedRoute,
		private modalCtrl: ModalController,
		private snackBar: MatSnackBar
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.locateAndShowStores(PreorderType.INSIDE);
		this.route.paramMap.subscribe(params => {
			console.log(params);
			console.log(this.router.url);
			if (params && params.get('venueId')) {
				console.log(params.get('venueId'), params.get('tableId'));
				const venueId =
					params.get('venueId') === '65f1999682e3d311180e2df4' ? '662f60e84ee2cdac1f04a5b0' : params.get('venueId');
				this.loadVenueWithTable(venueId, params.get('tableId'));
				return;
			}
			// if (params && params.get('venueId')) {
			// 	const venueId =
			// 		params.get('venueId') === '65f1999682e3d311180e2df4' ? '662f60e84ee2cdac1f04a5b0' : params.get('venueId');
			// 	const idPattern = /[a-f0-9]{24}/g;
			// 	const ids = venueId.match(idPattern);
			// 	if (ids) {
			// 		this.loadVenueByQrCode(venueId);
			// 	}
			// }
		});

		this.loading = false;
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;

		const venue = (await this.repository.getAllVenues())
			// .filter(venue => venue._id === '65578deb96195317f4e93a0f' || venue._id === '6647192a1b8f9164451c2025')
			.find(ven => venueAcceptsOrders(ven, preorderType));

		if (!venue) {
			console.log('No available venues');
			this.loading = false;
			return;
		}
		this.activeVenue = venue;
	}
	async selectVenue(venue: Venue) {
		this.loading = true;

		let result = await SelectVenuePage.show(this.modalCtrl, venue);
		if (result) {
			await MenuPage.navigate(this.router);
		}
		this.loading = false;
	}
	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		console.log('loadVenueAndCreateOrder(', venue, address, preorderType, ')');
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	async loadVenueWithTable(venueId: string, tableNumber: string) {
		this.loading = true;
		if (!tableNumber) {
			this.loadVenueByQrCode(venueId);
			return;
		}
		try {
			const venueData = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(this.venue._id, tableNumber)).data;
			await sleep(100);
			if (venueData && table) {
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.next(this.order);
				this.repository.venue.next(venueData);

				sleep(1000);
				await MenuPage.navigate(this.router);
			}
			this.loading = false;
		} catch (e) {
			this.snackBar.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000
			});
			this.loading = false;
		}
	}
	async loadVenueByQrCode(venueId: string) {
		console.log(venueId);

		this.loading = true;
		if (!venueId) {
			return;
		}
		try {
			const venueData = await this.repository.getVenue(venueId);
			const selectTable = await SelectTableModalComponent.show(this.modalCtrl, venueData, this.order);
			if (venueData && selectTable) {
				this.repository.createOrder(venueData, null, PreorderType.INSIDE);
				sleep(100);
				this.order.tableNumber = selectTable.table;
				this.order.table = selectTable.tableId;
				this.repository.order.emit(this.order);
				sleep(1000);
				// await MenuPage.navigate(this.router);
				this.router.navigateByUrl('/menu', {
					replaceUrl: true
				});
				return;
			}
			this.loading = false;
		} catch (e) {
			console.log(e);
			this.snackBar.open(this.translate.instant('home_page.scan_table_error'), null, {
				duration: 2000
			});
			this.loading = false;
		}
	}
	async selectLanguageModal() {
		await SelectLanguageModalComponent.show(this.modalCtrl);
	}
}
