import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const avecCustomerGroup = 'brezel_koenig';
export const customerGroup = avecCustomerGroup;

const supportEmail = {
	brezel_koenig: 'brezel_koenig@smoothr.de'
};
const firebaseConfig = {
	brezel_koenig: {
		apiKey: 'AIzaSyBCdU_ACStbV3huSwFmqpwILMMrGZJKQ6w',
		authDomain: 'avec-f17bd.firebaseapp.com',
		projectId: 'avec-f17bd',
		storageBucket: 'avec-f17bd.appspot.com',
		messagingSenderId: '120278036370',
		appId: '1:120278036370:web:4bd6790169b2db91a39b4b'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyBv5IfnRegn5x6r9X6mSliaZfgx99jyI6U',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: true,
	showOrderPriceFromBackend: false,
	showDiffrenceModal: false,
	payPalValidatePrice: false,
	payPalMinPrice: 0
};

import 'zone.js/dist/zone-error';
