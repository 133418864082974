import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalController} from '@ionic/angular';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import {numberD, numberToCurrency} from 'src/smoothr-web-app-core/utils/utils';
import {HomePage} from '../../home/home.page';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';
import {Router} from '@angular/router';

@Component({
	selector: 'app-payment-info',
	templateUrl: './payment-info.component.html',
	styleUrls: ['./payment-info.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentInfoComponent {
	public order: Order;
	public venue: Venue;
	numberToCurrency = numberToCurrency;
	OrderUtils = OrderUtils;
	numberD = numberD;

	constructor(
		private modalCtrl: ModalController,
		private router: Router,
		private repository: RepositoryService
	) {}

	static async show(modalCtrl: ModalController, order: Order) {
		const modal = await modalCtrl.create({
			cssClass: 'payment-info-modal ',
			component: PaymentInfoComponent,
			componentProps: {
				order
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}

	async home() {
		this.dismiss();
		await HomePage.navigate(this.router, this.repository);
	}
}
