import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {SharedModuleModule} from '../../shared-module/shared-module.module';
import {AddToHomeComponent} from '../../components/add-to-home/add-to-home.component';
import {HomePreorderTypeButtonComponent} from '../../components/home-preorder-type-button/home-preorder-type-button.component';
// import {HomePageSlidesComponent} from '../../components/home-page-slides/home-page-slides.component';
import {SelectVenuePage} from './select-venue.page';
import {RoutingSelectVenue} from './routing-select-venue.module';
import {DistancePipe} from 'src/app/pipe/distance.pipe';
import {WorkingHoursComponent} from 'src/app/components/working-hours/working-hours.component';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, SharedModuleModule],
	declarations: [SelectVenuePage, DistancePipe, WorkingHoursComponent]
})
export class SelectVenuePageModule {}
