import {Pipe, type PipeTransform} from '@angular/core';

@Pipe({
	name: 'distance'
})
export class DistancePipe implements PipeTransform {
	transform(value: number, unit: string = 'km'): string {
		let unitT = unit;
		if (value === null || value === undefined || isNaN(value)) {
			return '';
		}
		let valueT = +value.toFixed(1);
		if (valueT < 1) {
			unitT = 'm';
			valueT = valueT * 1000;
		}

		return `${valueT} ${unitT}`;
	}
}
