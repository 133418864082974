import {ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomModalWithCountrySelectionComponent} from './custom-modal-with-country-selection/custom-modal-with-country-selection.component';
import {ModalController} from '@ionic/angular';
import {countries} from './mock-countries';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-input-phone-with-county-code',
	templateUrl: './input-phone-with-county-code.component.html',
	styleUrls: ['input-phone-with-county-code.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputPhoneWithCountyCodeComponent),
			multi: true
		}
	]
})
export class InputPhoneWithCountyCodeComponent implements ControlValueAccessor, OnInit, OnChanges {
	countryCode = '+49';
	flag = 'de';
	countries = countries;
	@Input() code: string;
	@Input() phone: string;
	@Input() hasBackground: boolean = true;
	onChange: any = () => {};
	onTouched: any = () => {};
	form: FormGroup;

	constructor(
		private fb: FormBuilder,
		private modalCtrl: ModalController,
		private cd: ChangeDetectorRef
	) {
		this.form = this.fb.group({
			phone: '',
			country: this.countryCode
		});
	}

	ngOnInit() {
		this.form.valueChanges.subscribe(() => {
			this.onChange({
				phone: this.form.get('phone')?.value ?? '',
				country: this.form.get('country')?.value ?? this.countryCode
			});
		});
	}
	ngOnChanges(changes: SimpleChanges): void {}
	writeValue(phoneNumber: string | {phone: string; country: string}): void {
		if (typeof phoneNumber === 'string') {
			this.fromStringToObject(phoneNumber);
		}

		// this.form.patchValue(
		// 	{
		// 		country: '+49',
		// 	},
		// 	{ emitEvent: true }
		// );
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	async selectCountryCode() {
		const result = await CustomModalWithCountrySelectionComponent.show(this.modalCtrl, this.form.get('country')?.value);
		if (result?.dialCode) {
			this.flag = result.flagClass;
			this.form.patchValue(
				{
					country: '+' + result.dialCode
				},
				{emitEvent: true}
			);
		}
	}

	async fromStringToObject(phoneNumber: string) {
		if (phoneNumber) {
			const properCountry = this.countries.find(country => phoneNumber.replace('+', '').startsWith(country.dialCode));
			if (properCountry) {
				const code = '+' + properCountry.dialCode;
				const phone = phoneNumber.replace(code, '');

				this.flag = properCountry.isoCode;
				this.form.patchValue(
					{
						country: code,
						phone
					},
					{emitEvent: true}
				);

				await sleep(100);

				this.onChange({
					phone: this.form.get('phone')?.value ?? '',
					country: this.form.get('country')?.value ?? this.countryCode
				});
			}
		}
	}
}
